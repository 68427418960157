import controller_0 from '@symfony/ux-live-component/dist/live_controller.js';
import '@symfony/ux-live-component/dist/live.min.css';

export default [
{
        enabled: true,
        fetch: "eager",
        identifier: "live",
        controller: controller_0
      }
];
