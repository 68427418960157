/*
 * Welcome to your app's main JavaScript file!
 *
 * We recommend including the built version of this JavaScript file
 * (and its CSS file) in your base layout (base.html.twig).
 */
//
// make jQuery available everywhere
import './web-components/components.js'

// start the Stimulus application
import './bootstrap'
//
import './swiper'

import { Fancybox } from '@fancyapps/ui'
import '@fancyapps/ui/dist/fancybox/fancybox.css'
Fancybox.bind('[data-fancybox]', {
    // Your custom options
})

// alpine js
import Alpine from 'alpinejs'

import collapse from '@alpinejs/collapse'
Alpine.plugin(collapse)
window.Alpine = Alpine

Alpine.start()
