// import Swiper JS
import Swiper, { Navigation, Pagination } from 'swiper'
import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/pagination'

function docReady(fn) {
    // see if DOM is already available
    if (
        document.readyState === 'complete' ||
        document.readyState === 'interactive'
    ) {
        // call on next available tick
        setTimeout(fn, 1)
    } else {
        document.addEventListener('DOMContentLoaded', fn)
    }
}

docReady(function () {
    document
        .querySelectorAll('.swiper-container.triple-swiper')
        .forEach(function (elem) {
            const id = elem.id.replace('slider-', '')
            const swiperDiv = elem.querySelector('.swiper')

            new Swiper(swiperDiv, {
                modules: [Navigation, Pagination],
                // Optional parameters
                direction: 'horizontal',
                centeredSlides: false,
                spaceBetween: 20,
                slidesPerView: 1,
                breakpoints: {
                    576: {
                        slidesPerView: 1,
                        slidesToScroll: 1,
                        spaceBetween: 20
                    },
                    769: {
                        slidesPerView: 2,
                        slidesToScroll: 2,
                        spaceBetween: 20
                    },
                    992: {
                        slidesPerView: 3,
                        slidesToScroll: 3,
                        spaceBetween: 20
                    }
                },
                loop: false,
                pagination: {
                    clickable: true,
                    el: elem.querySelector('#pagination-' + id)
                },
                navigation: {
                    nextEl: elem.querySelector('#next-' + id),
                    prevEl: elem.querySelector('#prev-' + id)
                }
            })
        })

    document
        .querySelectorAll('.swiper-container.single-swiper')
        .forEach(function (elem) {
            const id = elem.id.replace('slider-', '')
            const swiperDiv = elem.querySelector('.swiper')

            new Swiper(swiperDiv, {
                modules: [Navigation, Pagination],
                // Optional parameters
                spaceBetween: 0,
                slidesPerView: 1,
                loop: false,
                pagination: {
                    clickable: true,
                    el: elem.querySelector('#pagination-' + id)
                },
                navigation: {
                    nextEl: elem.querySelector('#next-' + id),
                    prevEl: elem.querySelector('#prev-' + id)
                }
            })
        })
})
